<template>
    <div class="customer-files" v-loading="loading">
        <div class="customer-files-box">
            <div class="select-region">
                <div class="region-top-one">
                    <div class="region-one el_left">
                        <p>区域</p>
                    </div>
                    <div class="el_left region_content">
                        <div class="region-two el_left">
                            <div class="region-select">
                                <template v-for="(item, index) in areaArray">
                                    <p :key="index" :class="{'activeClass': activeIdx === index}" @click="selectArea(item, index)">
                                        {{ item.org_shortname }}
                                    </p>
                                </template>
                            </div>
                            <div class="business-select" v-if="enterpriseData.length>0">
                                <el-checkbox :indeterminate="isSelectAllEnterprise" v-model="isEnterprise" @change="selectAll('enterprise')">
                                    全选
                                </el-checkbox>
                                <el-checkbox-group v-model="selectEnterprise" @change="choice('enterprise')">
                                    <el-checkbox v-for="(item,index) in enterpriseData" :label="item.id" :key="index">
                                        {{ item.org_shortname }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </div>
                        <div class="region-three el_right">
                            <el-input placeholder="请输入客户编号或者名称..." v-model="search" class="input-with-select">
                                <el-button slot="append" icon="el-icon-search" @click="gettableData"></el-button>
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="default-hidden" v-if="!isShow">
                    <ul>
                        <li>
                            <h2>客户类型</h2>
                            <div>
                                <el-checkbox :indeterminate="isSelectAllUserType" v-model="isUserType" @change="selectAll('userType')">
                                    全选
                                </el-checkbox>
                                <el-checkbox-group v-model="selectUserType" @change="choice('userType')">
                                    <el-checkbox v-for="(item,index) in userTypeData" :label="item.code" :key="index">
                                        {{ item.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </li>
                        <li>
                            <h2>客户性质</h2>
                            <div>
                                <el-checkbox :indeterminate="isSelectAllUserNature" v-model="isUserNature" @change="selectAll('userNature')">
                                    全选
                                </el-checkbox>
                                <el-checkbox-group v-model="selectUserNature" @change="choice('userNature')">
                                    <el-checkbox v-for="(item,index) in userNatureData" :label="item.code" :key="index">
                                        {{ item.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </li>
                        <li>
                            <h2>资信等级</h2>
                            <div>
                                <el-checkbox :indeterminate="isSelectAllCreditRating" v-model="isCreditRating" @change="selectAll('creditRating')">
                                    全选
                                </el-checkbox>
                                <el-checkbox-group v-model="selectCreditRating" @change="choice('creditRating')">
                                    <el-checkbox v-for="(item,index) in creditRatingData" :label="item.name" :key="index">
                                        {{ item.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="retract-select" @click="showHiddenContent">
                    <p v-if="!isShow">
                        收起选项 <span class="iconfont">&#xe6a2;</span>
                    </p>
                    <p v-else>
                        展开选项 <span class="iconfont">&#xe6a1;</span>
                    </p>
                </div>
            </div>
            <div class="table-box" :style="{height: tableBoxHeight}">
                <div class="table-checkout">
                    <div class="checkout-left">
                        <div>
                            您已选择：
                        </div>
                    </div>
                    <div class="selectTag">
                        <template v-for="(item, index) in dynamicTags">
                            <el-tag
                                closable
                                size="mini"
                                :key="index"
                                @close="handleClose(item, index)"
                            >
                                {{ item.name }}
                            </el-tag>
                        </template>
                    </div>
                    <div class="checkout-right">
                        <el-link type="primary" @click="cleanArray">
                            清空条件
                        </el-link>
                    </div>
                </div>
                <div :style="{height: tableHeight}">
                    <el-table
                        :data="tableData"
                        style="width: 100%"
                        height="100%"
                        ref="ref_customerResourcesTable"
                        stripe
                    >
                        <el-table-column
                            prop="no"
                            label="客户编号"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="客户名称"
                            width="300"
                        >
                        </el-table-column>
                        <el-table-column
                            label="客户类型"
                        >
                            <template slot-scope="scope">
                                <span>{{ formatUserType(scope.row.type) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="pqname"
                            label="片区"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="qyname"
                            label="企业"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="nature_name"
                            label="客户性质"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="rel_client_name"
                            label="关联客户"
                        >
                        </el-table-column>
                        <el-table-column
                            label="登记时间"
                        >
                            <template slot-scope="scope">
                                <span>{{ formatTime(scope.row.created) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="credit"
                            label="资信等级"
                        >
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    @click.native.prevent="deleteRow(scope.row.id)"
                                    type="text"
                                    size="small"
                                >
                                    查看
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="tableSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 页面加载状态
            loading: false,
            // 查询条件是否展开
            isShow: false,
            // 区域
            areaArray: [],
            // 区域选中下标
            activeIdx: null,

            // 区域下企业数据
            enterpriseData: [],
            // 选中企业
            selectEnterprise: [],
            // 企业是否全选
            isSelectAllEnterprise: false,
            // 企业全选按钮状态
            isEnterprise: false,
            // 企业全选数据
            enterpriseCheckAll: [],

            // 用户类型数据
            userTypeData: [],
            // 选中用户类型
            selectUserType: [],
            // 用户类型是否全选
            isSelectAllUserType: false,
            // 用户类型全选按钮状态
            isUserType: false,
            // 用户类型全选数据
            userTypeCheckAll: [],

            // 用户性质数据
            userNatureData: [],
            // 选中用户性质
            selectUserNature: [],
            // 用户性质是否全选
            isSelectAllUserNature: false,
            // 用户性质全选按钮状态
            isUserNature: false,
            // 用户性质全选数据
            userNatureCheckAll: [],

            // 资信等级数据
            creditRatingData: [{
                code: 1,
                name: 'A',
            }, {
                code: 2,
                name: 'B',
            }, {
                code: 3,
                name: 'C',
            }, {
                code: 4,
                name: 'D',
            }],
            // 选中资信等级
            selectCreditRating: [],
            // 资信等级是否全选
            isSelectAllCreditRating: false,
            // 资信等级全选按钮状态
            isCreditRating: false,
            // 资信等级全选数据
            creditRatingCheckAll: ['A', 'B', 'C', 'D'],

            // 所有选中查询条件
            queryCriteria: {
                // 区域
                region: [],
                // 企业
                enterprise: [],
                // 用户类型
                userType: [],
                // 用户性质
                userNature: [],
                // 资信等级
                creditRating: [],
            },

            // 请求数据
            requestData: {
                // 客户编号/客户名称
                keywords: '',
                // 片区id
                treeids_pq: '',
                // 企业id
                treeids_qy: '',
                // 客户类型
                type: '',
                // 客户性质
                nature: '',
                // 客户资信评级
                credit: '',
            },

            // 搜索框查询条件
            search: '',
            // tag标签数据
            dynamicTags: [],
            // 列表当前页
            currentPage: 1,
            // 列表每页条数
            tableSize: 10,
            // 列表总条数
            tableTotal: 0,
            // 列表数据
            tableData: [],
            // 列表高度
            tableHeight: '',

            tableBoxHeight: '',
        };
    },
    async created() {
        this.loading = true;
        // 获取片区数据
        await this.getRegionalData();
        // 获取用户类型数据
        await this.getUserTypeData();
        // 获取用户性质数据
        await this.getUserNatureData();
        // 获取列表数据
        await this.gettableData();
    },
    watch: {
        search(newVel) {
            this.requestData.keywords = newVel;
        },
        queryCriteria: {
            deep: true,
            handler: function (newVel) {
                // 处理已选查询数据
                this.processSelectedData(newVel);

                // 片区
                let newRegion = '';
                newVel.region.forEach((item, index) => {
                    if (index === newVel.region.length - 1) {
                        newRegion += item.code;
                    } else {
                        newRegion += item.code + ',';
                    }
                });
                this.requestData.treeids_pq = newRegion;

                // 企业
                let newEnterprise = '';
                newVel.enterprise.forEach((item, index) => {
                    if (index === newVel.enterprise.length - 1) {
                        newEnterprise += item.code;
                    } else {
                        newEnterprise += item.code + ',';
                    }
                });
                this.requestData.treeids_qy = newEnterprise;

                // 客户类型
                let newUserType = '';
                newVel.userType.forEach((item, index) => {
                    if (index === newVel.userType.length - 1) {
                        newUserType += item.code;
                    } else {
                        newUserType += item.code + ',';
                    }
                });
                this.requestData.type = newUserType;

                // 客户性质
                let newUserNature = '';
                newVel.userNature.forEach((item, index) => {
                    if (index === newVel.userNature.length - 1) {
                        newUserNature += item.code;
                    } else {
                        newUserNature += item.code + ',';
                    }
                });
                this.requestData.nature = newUserNature;

                // 客户资信评级
                let newCreditRating = '';
                newVel.creditRating.forEach((item, index) => {
                    if (index === newVel.creditRating.length - 1) {
                        newCreditRating += item.code;
                    } else {
                        newCreditRating += item.code + ',';
                    }
                });
                this.requestData.credit = newCreditRating;

                // 获取列表数据
                this.gettableData();
            },
        },
        // 区域
        activeIdx(newVel) {
            if (newVel !== null) {
                this.queryCriteria.region = [];
                // 区域查询条件赋值
                this.queryCriteria.region.push({
                    name: this.areaArray[newVel].org_shortname,
                    code: this.areaArray[newVel].id,
                    type: 'region',
                });
            } else {
                this.queryCriteria.region = [];
                // 区域下企业数据
                this.enterpriseData = [];
                // 选中企业
                this.selectEnterprise = [];
                // 企业是否全选
                this.isSelectAllEnterprise = false;
                // 企业全选按钮状态
                this.isEnterprise = false;
                // 企业全选数据
                this.enterpriseCheckAll = [];
            }
        },
        // 企业
        selectEnterprise(newVel) {
            this.queryCriteria.enterprise = [];
            this.enterpriseData.forEach(item => {
                newVel.forEach(k => {
                    if (item.id === k) {
                        // 企业查询条件赋值
                        this.queryCriteria.enterprise.push({
                            name: item.org_shortname,
                            code: item.id,
                            type: 'enterprise',
                        });
                    }
                });
            });
        },
        // 用户类型
        selectUserType(newVel) {
            this.queryCriteria.userType = [];
            this.userTypeData.forEach(item => {
                newVel.forEach(k => {
                    if (item.code === k) {
                        // 用户类型条件赋值
                        this.queryCriteria.userType.push({
                            name: item.name,
                            code: item.code,
                            type: 'userType',
                        });
                    }
                });
            });
        },
        // 用户性质
        selectUserNature(newVel) {
            this.queryCriteria.userNature = [];
            this.userNatureData.forEach(item => {
                newVel.forEach(k => {
                    if (item.code === k) {
                        // 用户性质条件赋值
                        this.queryCriteria.userNature.push({
                            name: item.name,
                            code: item.code,
                            type: 'userNature',
                        });
                    }
                });
            });
        },
        // 资信等级
        selectCreditRating(newVel) {
            this.queryCriteria.creditRating = [];
            this.creditRatingData.forEach(item => {
                newVel.forEach(k => {
                    if (item.name === k) {
                        // 资信等级条件赋值
                        this.queryCriteria.creditRating.push({
                            name: item.name,
                            code: item.name,
                            type: 'creditRating',
                        });
                    }
                });
            });
        },
    },
    mounted() {
        window.onresize = () => {
            this.$nextTick(() => {
                // 计算列表高度
                this.calculationTableHeight();
            });
        };
    },
    methods: {
        // 是否展开查询条件
        showHiddenContent() {
            if (this.isShow) {
                this.isShow = false;
            } else {
                this.isShow = true;
            }
            this.$nextTick(() => {
                // 计算列表高度
                this.calculationTableHeight();
            });
        },
        // 获取片区数据
        async getRegionalData() {
            await this.$axios
                .get('/interfaceApi/basicplatform/orgtree/orgtype/OT000002/3?page=1&row=999')
                .then(res => {
                    if (res.List.length > 0) {
                        this.areaArray = res.List;
                    }
                }).catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取区域下企业数据
        async getEnterpriseData(id) {
            this.enterpriseData = [];
            await this.$axios
                .get(`/interfaceApi/basicplatform/orgtree/orgtype/${id}/5?page=1&row=999`)
                .then(res => {
                    if (res.List.length > 0) {
                        this.enterpriseData = res.List;
                        res.List.forEach(item => {
                            this.enterpriseCheckAll.push(item.id);
                        });
                        // 计算列表高度
                        this.calculationTableHeight();
                    }
                }).catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取用户类型数据
        async getUserTypeData() {
            await this.$axios.get('/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=002-FL0&type=0')
                .then(res => {
                    if (res.length > 0) {
                        this.userTypeData = res;
                        res.forEach(item => {
                            this.userTypeCheckAll.push(item.code);
                        });
                    }
                }).catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取用户性质数据
        async getUserNatureData() {
            await this.$axios.get('/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=002-XZ0&type=0')
                .then(res => {
                    if (res.length > 0) {
                        this.userNatureData = res;
                        res.forEach(item => {
                            this.userNatureCheckAll.push(item.code);
                        });
                    }
                }).catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择区域
        selectArea(data, index) {
            this.activeIdx = index;
            // 获取区域下企业数据
            this.getEnterpriseData(data.id);
            this.queryCriteria.region = [];

            // 区域查询条件赋值
            this.queryCriteria.region.push({
                name: this.areaArray[index].org_shortname,
                code: this.areaArray[index].id,
                type: 'region',
            });

            // 选中企业
            this.selectEnterprise = [];
            // 企业是否全选
            this.isSelectAllEnterprise = false;
            // 企业全选按钮状态
            this.isEnterprise = false;
            // 企业全选数据
            this.enterpriseCheckAll = [];
            this.queryCriteria.enterprise = [];
        },
        // 全选
        selectAll(type) {
            if (type === 'enterprise') {
                if (this.isEnterprise) {
                    this.selectEnterprise = JSON.parse(JSON.stringify(this.enterpriseCheckAll));
                } else {
                    this.selectEnterprise = [];
                }
                this.isSelectAllEnterprise = false;
            } else if (type === 'userType') {
                if (this.isUserType) {
                    this.selectUserType = JSON.parse(JSON.stringify(this.userTypeCheckAll));
                } else {
                    this.selectUserType = [];
                }
                this.isSelectAllUserType = false;
            } else if (type === 'userNature') {
                if (this.isUserNature) {
                    this.selectUserNature = JSON.parse(JSON.stringify(this.userNatureCheckAll));
                } else {
                    this.selectUserNature = [];
                }
                this.isSelectAllUserNature = false;
            } else if (type === 'creditRating') {
                if (this.isCreditRating) {
                    this.selectCreditRating = JSON.parse(JSON.stringify(this.creditRatingCheckAll));
                } else {
                    this.selectCreditRating = [];
                }
                this.isSelectAllCreditRating = false;
            }
        },
        // 选择
        choice(type) {
            if (type === 'enterprise') {
                const checkedCount = this.selectEnterprise.length;
                this.isEnterprise = checkedCount === this.enterpriseData.length;
                this.isSelectAllEnterprise = checkedCount > 0 && checkedCount < this.enterpriseData.length;
            } else if (type === 'userType') {
                const checkedCount = this.selectUserType.length;
                this.isUserType = checkedCount === this.userTypeData.length;
                this.isSelectAllUserType = checkedCount > 0 && checkedCount < this.userTypeData.length;
            } else if (type === 'userNature') {
                const checkedCount = this.selectUserNature.length;
                this.isUserNature = checkedCount === this.userNatureData.length;
                this.isSelectAllUserNature = checkedCount > 0 && checkedCount < this.userNatureData.length;
            } else if (type === 'creditRating') {
                const checkedCount = this.selectCreditRating.length;
                this.isCreditRating = checkedCount === this.creditRatingData.length;
                this.isSelectAllCreditRating = checkedCount > 0 && checkedCount < this.creditRatingData.length;
            }
        },
        // 处理已选查询数据
        processSelectedData(data) {
            this.dynamicTags = [...data.region, ...data.enterprise, ...data.userType, ...data.userNature, ...data.creditRating ];
            // 计算列表高度
            this.calculationTableHeight();
        },

        // tag 删除条件
        handleClose(data) {
            if (data.type === 'region') {
                this.activeIdx = null;
            } else if (data.type === 'enterprise') {
                this.selectEnterprise.forEach((item, index) => {
                    if (item === data.code) {
                        this.selectEnterprise.splice(index, 1);
                        // 企业全选按钮状态
                        this.isEnterprise = false;

                        if (this.selectEnterprise.length > 0) {
                            // 企业是否全选
                            this.isSelectAllEnterprise = true;
                        } else {
                            // 企业是否全选
                            this.isSelectAllEnterprise = false;
                        }
                    }
                });
            } else if (data.type === 'userType') {
                this.selectUserType.forEach((item, index) => {
                    if (item === data.code) {
                        this.selectUserType.splice(index, 1);
                        // 用户类型全选按钮状态
                        this.isUserType = false;

                        if (this.selectUserType.length > 0) {
                            // 用户类型是否全选
                            this.isSelectAllUserType = true;
                        } else {
                            // 用户类型是否全选
                            this.isSelectAllUserType = false;
                        }
                    }
                });
            } else if (data.type === 'userNature') {
                this.selectUserNature.forEach((item, index) => {
                    if (item === data.code) {
                        this.selectUserNature.splice(index, 1);
                        // 用户性质全选按钮状态
                        this.isUserNature = false;

                        if (this.selectUserNature.length > 0) {
                            // 用户性质是否全选
                            this.isSelectAllUserNature = true;
                        } else {
                            // 用户性质是否全选
                            this.isSelectAllUserNature = false;
                        }
                    }
                });
            } else if (data.type === 'creditRating') {
                this.selectCreditRating.forEach((item, index) => {
                    if (item === data.code) {
                        this.selectCreditRating.splice(index, 1);
                        // 资信等级全选按钮状态
                        this.isCreditRating = false;

                        if (this.selectCreditRating.length > 0) {
                            // 资信等级是否全选
                            this.isSelectAllCreditRating = true;
                        } else {
                            // 资信等级是否全选
                            this.isSelectAllCreditRating = false;
                        }
                    }
                });
            }
            this.calculationTableHeight();
        },
        // 清空所有查询条件
        cleanArray() {
            // 区域选中下标
            this.activeIdx = null;
            // 选中用户类型
            this.selectUserType = [];
            // 用户类型全选按钮状态
            this.isUserType = false;
            // 用户类型是否全选
            this.isSelectAllUserType = false;

            // 选中用户性质
            this.selectUserNature = [];
            // 用户性质全选按钮状态
            this.isUserNature = false;
            // 用户性质是否全选
            this.isSelectAllUserNature = false;

            // 选中资信等级
            this.selectCreditRating = [];
            // 资信等级全选按钮状态
            this.isCreditRating = false;
            // 资信等级是否全选
            this.isSelectAllCreditRating = false;

            // 搜索框查询条件
            this.search = '';
        },
        // 获取列表数据
        gettableData() {
            this.loading = true;
            this.$axios
                .post(`/interfaceApi/sale/client_manager/get_jt_client?pageindex=${this.currentPage}&pageSize=${this.tableSize}`, this.requestData)
                .then(res => {
                    this.$set(this, 'tableData', res.rows);
                    this.tableTotal = res.total;

                    this.$nextTick(() => {
                        this.calculationTableHeight();
                        this.$refs.ref_customerResourcesTable.doLayout();
                    });
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 每页多少条
        handleSizeChange(val) {
            // 列表每页条数
            this.tableSize = val;
            // 获取列表数据
            this.gettableData();
        },
        // 加载第几页数据
        handleCurrentChange(val) {
            // 列表当前页
            this.currentPage = val;
            // 获取列表数据
            this.gettableData();
        },
        // 计算列表高度
        calculationTableHeight() {
            const sHeight = this.$('.customer-files-box').height();
            const queryAreaHeight = this.$('.select-region').height();
            const dynamicTagsHeight = this.$('.table-checkout').height();
            const newHeight = sHeight - queryAreaHeight;
            const newTableHeight = newHeight - dynamicTagsHeight - 40;
            this.tableBoxHeight = newHeight + 'px';
            this.tableHeight = newTableHeight + 'px';
        },
        // 格式化列表显示用户类型
        formatUserType(data) {
            let newType = '';
            this.userTypeData.forEach(item => {
                if (item.code === data) {
                    newType = item.name;
                }
            });
            return newType;
        },
        // 格式化列表显示时间
        formatTime(data) {
            const newDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(data)[0];
            return newDate;
        },
        // 列表查看
        deleteRow(id) {
            this.$YWShowLayer('FormCode=Form202001081000270224&IsView=true', id);
        },
    },
};

</script>
<style lang='stylus'>
.customer-files
    .customer-files-box
        background #ffffff
        width 100%
        height 100%
        padding 0.2rem
        .el-checkbox
            margin-right 0.3rem
            &:last-of-type
                margin-right 0.3rem
        .region-top-one
            overflow hidden
            .region-one
                display flex
                align-items center;
                font-size .16rem
                font-weight 600
                width 1.1rem
                justify-content space-between
                p
                    letter-spacing 0.29rem
            .region_content
                width calc(100% - 1.1rem)
                padding-left 0.1rem
                border-bottom .01rem solid #F0F0F0
                display flex
                justify-content space-between
                .region-two
                    width calc(100% - 4.3rem)
                    .region-select
                        display flex
                        padding-bottom .2rem
                        p
                            margin-right .3rem;
                            color #666666
                            font-size .16rem
                            cursor:pointer;
                        .activeClass
                            color #419EFF
                    .business-select
                        padding-bottom .15rem
                        display flex
                        .el-radio
                            margin-right .2rem
                            width: 0.6rem;
                        .el-radio__label
                            padding-left 0
                        .el-radio__inner
                            width .16rem
                            height .16rem
                .region-three
                    .el-input-group
                        vertical-align top
                    .el-input__inner
                        width 3.4rem
                        height .36rem
                    .el-input-group__append
                        width .5rem
                        height .36rem
                        background #1577D2
                        color #fff
                        font-size 0.18rem
                        border 1px solid #1577D2
        .select-region
            display flex
            flex-direction column
            .default-hidden
                display flex
                ul
                    display flex
                    flex-direction column
                    width 100%
                    li
                        width 100%
                        align-items center;
                        h2
                            color #333333
                            font-size .16rem
                            line-height 0.52rem
                            font-weight 600
                            width 1.1rem
                            float left
                        >div
                            float left
                            padding-left 0.1rem
                            height 100%
                            width calc(100% - 1.1rem)
                            display flex
                            align-items center
                            border-bottom .01rem solid #F0F0F0
                            .el-radio
                                margin-right .2rem
                            .el-radio__label
                                padding-left 0
                            .el-radio__inner
                                width .16rem
                                height .16rem
            .retract-select
                width 100%
                height .53rem
                display flex
                justify-content center
                align-items center
                &:hover
                    cursor pointer
                    background rgba(240,240,240,0.3)
                p
                    color #666666
                    font-size .16rem
                    display flex
                    justify-content center
                    align-items center
                    span
                        font-size .16rem
                        margin-left 0.05rem
        .table-box
            .table-checkout
                display flex
                align-items center
                justify-content space-between
                min-height .43rem
                .checkout-left
                    display flex
                    width 1rem
                    color #666666
                    font-size .16rem
                .selectTag
                    width calc(100% - 1.8rem)
                    text-align left
                    .el-tag
                        margin-right 0.15rem
                .checkout-right
                    width 0.8rem
                    text-align right
                .el-link--inner
                    font-size .16rem
            .el-table
                border 1px solid #D7D7D7
                th,td
                    padding 0
                    height 0.4rem
                    font-size 0.16rem
                    text-align center
                th
                    color #012781
                    background #EDEFF5
                td
                    font-size 0.14rem
                    color #333
            .el-pagination
                margin-top 0.1rem
                text-align right
                .el-pager
                    li
                        color #979DA7
                        font-weight 400
                        &.active,
                        &:hover
                            color #D30004
                .btn-prev
                    color #D7D7D7
                .el-input__icon
                    line-height 0.26rem
</style>